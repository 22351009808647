<template>
  <div>
    <div
      v-if="!hasSnapshot && !loading"
      class="min-h-50 d-flex w-100 align-items-center justify-content-center"
    >
      <div class="text-center">
        <b-icon icon="map" class="h1" />
        <p class="my-3">
          Ainda não há informações de localização sincronizadas.
        </p>

        <b-button v-b-modal.select-branches v-if="is_provider && enabled">
          <b-icon icon="arrow-down-up" class="mr-2" />
          <span>Sincronizar agora</span>
        </b-button>
      </div>
    </div>
    <div v-else>
      <div class="d-flex align-items-center justify-content-start mb-3">
        <small class="mr-2">
          Ultima atualização {{ location.updated_at | dateFull }}
        </small>
        <unicon
          v-if="is_provider && enabled"
          name="redo"
          v-b-modal.select-branches
          class="pointer small"
          style="font-size: 12px"
          height="16"
          width="16"
        />
      </div>
      <div id="location-techinical" v-if="hasSnapshot && !loading">
        <b-card class="details map no-border" body-class="p-md-2 p-0">
          <div>
            <small>Tomador</small>
            <p>{{ borrower.social_reason }}</p>
            <section class="d-flex">
              <b-icon icon="map" class="mr-2 mt-1" />
              <p>
                {{ concatAddress("borrower") }}
              </p>
            </section>
          </div>
          <hr />
          <div class="mt-4">
            <small>Prestador</small>
            <p>{{ provider.social_reason }}</p>
            <table class="w-100 mb-3">
              <tr>
                <td colspan="2"><strong>Denominação</strong></td>
                <td>{{ provider.fantasy_name }}</td>
              </tr>
              <tr>
                <td colspan="2"><strong>CNPJ</strong></td>
                <td>11.167.007/0001-19</td>
              </tr>
              <tr>
                <td colspan="2"><strong>Inscrição estadual</strong></td>
                <td>ISENTA</td>
              </tr>
              <tr>
                <td colspan="2"><strong>Telefone</strong></td>
                <td>31 3444-4615</td>
              </tr>
            </table>

            <section class="d-flex">
              <b-icon icon="map" class="mr-2 mt-1" />
              <p>
                {{ concatAddress("provider") }}
              </p>
            </section>
          </div>
          <hr />
          <div>
            <p>Distância da base operacional</p>
            <table class="mb-3">
              <tr>
                <td>Desejado:</td>
                <td class="pl-3">
                  <strong>Até {{ distanceDesired | number }} km</strong>
                </td>
              </tr>
              <tr>
                <td>Aferido:</td>
                <td class="pl-3">
                  <b-badge
                    class="justify-content-center"
                    :variant="distance < distanceDesired ? 'success' : 'danger'"
                  >
                    {{ distance | number }} km
                  </b-badge>
                </td>
              </tr>
            </table>
          </div>
        </b-card>

        <gmap-map :center="center" :zoom="5" class="map-provider">
          <gmap-polyline
            :path.sync="path"
            :options="{ strokeColor: '#347cff' }"
          />
          <gmap-marker
            v-for="(marker, index) in markers"
            :position="marker.position"
            :clickable="false"
            :draggable="false"
            @click="center = marker.position"
            :key="index"
          >
          </gmap-marker>
        </gmap-map>
      </div>
    </div>
    <b-modal
      id="select-branches"
      size="lg"
      centered
      scrollable
      hide-header
      hide-footer
    >
      <template #modal-header> </template>
      <div class="py-3">
        <ModalSelect @close="handleSetSelecteds" />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
import { mapActions, mapGetters } from "vuex";
import ModalSelect from "./ModalSelect.vue";

export default {
  components: {
    ModalSelect,
  },

  data() {
    return {
      hasSnapshot: false,
      enabled: false,
      loading: false,
      distanceDesired: 0,
      borrower: "",
      provider: "",
      distance: "",
      service_id: "",
      provider_id: "",
      location: {},
      homologationId: "",
      center: {
        lat: 0.0,
        lng: 0.0,
      },
      path: [],
      markers: [
        {
          position: {
            lat: 0.0,
            lng: 0.0,
          },
        },
        {
          position: {
            lat: 0.0,
            lng: 0.0,
          },
        },
      ],
    };
  },
  computed: {
    google: gmapApi,
    ...mapGetters(["is_preview", "user", "preview_personify", "is_provider"]),
  },
  methods: {
    ...mapActions(["get_snapshot", "set_snapshot", "show_loading"]),
    concatAddress(type) {
      let address = [];
      if (!this[type]) {
        return;
      }
      address.push(this[type].address.place);
      address.push(this[type].address.number);
      address.push(this[type].address.neighborhood);
      address.push(this[type].address.city);
      address.push(this[type].address.state);
      address = address.filter((item) => item !== "");
      return address.join(", ");
    },
    getSnapshot() {
      this.loading = true;
      this.show_loading({
        loading: true,
      });
      this.get_snapshot({
        type: "location-base",
        service_id: this.service_id,
        provider_id: this.provider_id,
      }).then(({ data, ...response }) => {
        this.enabled = response.enabled;
        this.loading = false;
        this.show_loading({
          loading: false,
        });
        this.$bvModal.hide("modal-loading-snapshot");
        if (!(data && data.borrower)) {
          this.hasSnapshot = false;
          return;
        }
        this.location = response;
        this.hasSnapshot = true;
        this.distance = (data.distance.measured / 1000).toFixed(2);
        this.distanceDesired = data.distance.desired / 1000;
        this.borrower = data.borrower;
        this.provider = data.provider;

        this.path = data.path.coordinates;
        this.markers[0].position.lng = data.origin.coordinates.lng;
        this.markers[0].position.lat = data.origin.coordinates.lat;

        this.markers[1].position.lng = data.destination.coordinates.lng;
        this.markers[1].position.lat = data.destination.coordinates.lat;

        this.center = data.center.coordinates;
      });
    },

    handleSetSelecteds(branche) {
      this.$bvModal.hide("select-branches");
      this.$bvModal.show("modal-generating-snapshot");
      this.set_snapshot({
        type: "location-base",
        service_id: this.service_id,
        provider_id: this.provider_id,
        data: branche.id,
      }).then(() => {
        this.$bvModal.hide("modal-generating-snapshot");
        this.$bvModal.hide("modal-generating-snapshot");
        this.hasSnapshot = true;
        this.getSnapshot();
      });
    },
  },
  mounted() {
    this.service_id = this.$route.params.service_id;
    this.provider_id = this.$route.params.provider_id;
    this.homologationId = this.$route.params.homologation_id;
    this.getSnapshot();
  },
};
</script>
